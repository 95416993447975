/* @font-face {font-family: 'Brandon'; src: url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/905847/BrandonText-Regular.otf')format('opentype');} */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 1, 2021 */
@font-face { font-family: 'Brandon'; src: url('fonts/brandontext-bold-webfont.woff2') format('woff2'), url('fonts/brandontext-bold-webfont.woff') format('woff'); font-weight: 700; font-style: normal; }
@font-face { font-family: 'Brandon'; src: url('fonts/brandontext-light-webfont.woff2') format('woff2'), url('fonts/brandontext-light-webfont.woff') format('woff'); font-weight: 300; font-style: normal; }
@font-face { font-family: 'Brandon'; src: url('fonts/brandontext-regular-webfont.woff2') format('woff2'), url('fonts/brandontext-regular-webfont.woff') format('woff'), url('https://d2saw6je89goi1.cloudfront.net/uploads/digital_asset/file/905847/BrandonText-Regular.otf'); font-weight: 400; font-style: normal; }
@font-face { font-family: 'Brandon'; src: url('fonts/brandontext-regularitalic-webfont.woff2') format('woff2'), url('fonts/brandontext-regularitalic-webfont.woff') format('woff'); font-weight: 400; font-style: italic; }
@font-face { font-family: 'Brandon'; src: url('fonts/brandontext-thin-webfont.woff2') format('woff2'), url('fonts/brandontext-thin-webfont.woff') format('woff'); font-weight: 100; font-style: normal; }

@font-face {
  font-family: 'cocosharpregular';
  src: url('fontsCoco/zetafonts_-_cocosharp_regular-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_regular-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_regular-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpbold';
  src: url('fontsCoco/zetafonts_-_cocosharp_bold-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_bold-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_bold-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpbold_italic';
  src: url('fontsCoco/zetafonts_-_cocosharp_bold_italic-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_bold_italic-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_bold_italic-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_bold_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpextrabold_italic';
  src: url('fontsCoco/zetafonts_-_cocosharp_extrabold_italic-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_extrabold_italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold_italic-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold_italic-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpextrabold';
  src: url('fontsCoco/zetafonts_-_cocosharp_extrabold-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_extrabold-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_extrabold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpextralight_italic';
  src: url('fontsCoco/zetafonts_-_cocosharp_extralight_italic-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_extralight_italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight_italic-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight_italic-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpextralight';
  src: url('fontsCoco/zetafonts_-_cocosharp_extralight-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_extralight-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_extralight-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpheavy_italic';
  src: url('fontsCoco/zetafonts_-_cocosharp_heavy_italic-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_heavy_italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy_italic-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy_italic-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpheavy';
  src: url('fontsCoco/zetafonts_-_cocosharp_heavy-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_heavy-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_heavy-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharpitalic';
  src: url('fontsCoco/zetafonts_-_cocosharp_italic-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_italic-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_italic-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_italic-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'cocosharplight';
  src: url('fontsCoco/zetafonts_-_cocosharp_light-webfont.eot');
  src: url('fontsCoco/zetafonts_-_cocosharp_light-webfont.eot?#iefix') format('embedded-opentype'),
       url('fontsCoco/zetafonts_-_cocosharp_light-webfont.woff2') format('woff2'),
       url('fontsCoco/zetafonts_-_cocosharp_light-webfont.woff') format('woff'),
       url('fontsCoco/zetafonts_-_cocosharp_light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

/*-------- reset css -----------*/
html,body {-webkit-text-size-adjust: none; -moz-text-size-adjust:none; -ms-text-size-adjust:none;}
html{ font-size: 1rem; line-height: 1.3em; padding: 0; margin: 0px; }
body { font-family: 'cocosharpregular', Helvetica, sans-serif; font-weight: 400; font-size: inherit; line-height: inherit; color: #707070; padding: 0; margin: 0px; }
a { color: #714694 }
a:hover { color: #707070; text-decoration: none;  }
a[href^="tel:"]{color:#707070;text-decoration:none;cursor:default;}
a[href^="tel:"]:hover{color:#707070;text-decoration:none;}
a[href^="tel:"]:focus{text-decoration:none;color:#707070;}
:focus { outline: 0px none; }
a:focus, a:active { border: none; outline: 0;text-decoration: none;color: #707070; }
.invisible { position: absolute; left: -9999999px; top: auto; width: 1px; height: 1px; overflow: hidden; visibility: visible; }
button:focus { outline: 0px auto -webkit-focus-ring-color; }
img { outline-style: none; outline-width: 0; border: 0 none; max-width: 100%; height: auto; }
sup {font-size:0.7em;}
sub { margin: 2px 0; padding: 2px 0;font-size:0.7em;}
ul { list-style-image: none; list-style-position: outside; list-style-type: none; }
ol { list-style-image: none; list-style-position: outside; list-style-type: decimal; }
.content-page ul { list-style-type: none; padding-left: 25px; margin-bottom: 10px; }
.content-page ul ul { list-style-type: none; margin-top: 19px; }
.content-page ol ol{margin-top: 19px;}
.content-page ul li, .content-page ol li { margin-bottom: 19px; }
.content-page ul li{position:relative;}
.content-page ul li:before{content:'';position:absolute;width:4px;height:4px;background:#00a7c8;top: 8px;left: -16px;display:inline-block;}
.content-page ol { list-style-type: decimal; padding-left: 22px; margin-bottom: 10px; }
.form-control:focus { box-shadow: 0 0 0 2px rgba(60, 171, 253, 0.5); }
hr { border-style: none none solid; border-width: 0 0 1px; margin: 5px 0; }
:active { outline-style: none; outline-width: 0; }
p { padding: 0 0 15px; }
video:focus {
  outline: 0px;
}
h1, h2, h3, h4, h5, h6 { margin-bottom: 0px; }
h1,.h1 { font-size:1.500em;padding: 0px 0px 15px; margin:0px;font-weight: 700; color: #444}
.content-page h1 { padding: 20px 0px 20px; }
h1.elipsis-h1, h1 { padding: 0px; }
h2,.h2 { font-size: 1.375em;padding: 0px 0px 15px;font-weight: 600; color: #444}
h3,.h3 { font-size: 1.250em;padding: 0px 0px 15px;font-weight: 600; color: #444 }
h4,.h4 { font-size: 1.125em;padding: 0px 0px 15px;font-weight: 400; color: #444 }
.pad-top{ padding-top:20px; }
.uppercase{text-transform:uppercase;}
input:focus { border: 0px none; outline-width: 0px; }
.global-page-header { padding-bottom: 20px; }
.global-page-header-2-text { padding-bottom: 10px; font-weight: 600; position: relative; padding-right: 30px; display: inline-block; }
.global-page-header-2-arrow { color: #444; display: inline-block; padding-left: 0px; position: absolute;right: 5px; top: 3px; }
.section-header-container { position: relative; }
.section-header-with-dropdown-container-outer.section-header-container-music { margin-top: 0px; margin-bottom: 15px; }
.section-header-right-container { position: absolute; right: 0px; top: 0px; }
.section-header-right-link-arrow { color: #000; display: inline-block; padding-left: 0px; position: absolute;right: 0px; top: -2px; }
.section-header-right-link { position: relative; padding-right: 25px; }
.no-pad-left { padding-left: 0px !important; }

.app-button { height: 40px; padding-left: 30px; padding-right: 30px; background-color: #C2F7EA; border-radius: 20px; border: 0px none; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out }
.app-button:hover { background-color: #A8EDDC; }
.app-button-text { text-align: center; font-weight: normal; font-size: 12px; line-height: 13px; letter-spacing: 1.3px; text-transform: uppercase;color: #47207C; font-family: 'cocosharpbold'; }
.app-button.big { height: 50px; border-radius: 25px; }
.app-button.big .app-button-text { font-size: 12px; line-height: 14px; letter-spacing: 1px; color: #000 }
.app-button.big .app-button-text.more-l-s { letter-spacing: 2px; }

.app-button-outline { height: 40px; padding-left: 30px; padding-right: 30px; background-color: transparent; border-radius: 20px; background-color: #fff; border: 0px none }
.app-button-outline-text { text-align: center; font-family: 'cocosharpbold'; font-size: 12px; line-height: 17px; letter-spacing: 2px; text-transform: uppercase;color: #000000; }
.app-button-outline-icon { color: #000000; margin-right: 10px; }
.app-button-outline.big { height: 50px; border-radius: 25px; }
.app-button-outline.big .app-button-outline-text { font-size: 12px; line-height: 22px; letter-spacing: 1px; }
.app-button.purple { background-color: #916AAA; }
.app-button.purple:hover { background-color: #714694; }
.app-button.purple .app-button-text { color: #fff; }
.app-button-capitalize { text-transform: capitalize; }
.no-marg-left { margin-left: 0px !important; }
.no-marg-right { margin-right: 0px !important; }
.no-marg-top { margin-top: 0px !important; }
.no-marg-bottom { margin-bottom: 0px !important; }
.no-pad-bottom { padding-bottom: 0px !important; }
.no-pad-left { padding-left: 0px !important; }
.no-pad-right { padding-right: 0px !important; }
.no-pad-top { padding-top: 0px !important; }
.h-a-player { display: none !important; }
/*-------- reset css -----------*/

/* = = = = = = = = mm = = = = = = = = */
.main-site-wrapper { max-width: 1440px; margin: 0 auto; /*background-color: #fff;*/ }
.m-m-cont { padding: 80px 116px; background-image: url('images/stars.png'); background-position: center; background-repeat: no-repeat; background-size: cover; }
.m-m-top-cont { max-width: 558px; margin: 0 auto; }
.m-m-top-title { font-style: normal; font-weight: normal; font-size: 48px; line-height: 58px; text-align: center; color: #FFFFFF; font-family: 'cocosharpextrabold'; }
.m-m-top-subtitle { font-style: normal; font-weight: normal; font-size: 18px; line-height: 22px; text-align: center; color: #FFFFFF; padding-top: 20px;padding-bottom: 20px; }
.m-m-middle-item-inner { border-radius: 20px; width: 100%; padding-bottom: 100%; padding-top: 0px;  height: 0px; position: relative; background-position: center center; background-size: cover; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out }
.m-m-middle-item-inner:hover { box-shadow: 0px 0px 50px #714694; -webkit-box-shadow: 0px 0px 50px #714694;; -moz-box-shadow: 0px 0px 50px #714694;; box-shadow: 0px 0px 50px #714694; }
.m-m-middle-item-cont { margin-bottom: 50px; }
.m-m-middle-item-image { height: 100%; width: 100%; }
.m-m-middle-item-image-cont { position: absolute; width: 100%; height: 100%; z-index: 2; left: 0; top: 0 }
.m-m-middle-item-title-cont { position: absolute; left: 20px; bottom: 15px; width: 100% }
.m-m-middle-item-title { text-align: left; color: #fff; font-family: 'cocosharpextrabold'; font-size: 23.4857px; line-height: 110%; color: #FFFFFF; text-shadow: 0px 2.60952px 2.60952px rgba(0, 0, 0, 0.25); }
.m-m-middle-item-subtitle { font-style: normal; font-family: 'cocosharpbold'; font-size: 11.7429px; line-height: 110%; letter-spacing: 1.30476px; color: #FFFFFF; text-shadow: 0px 2.60952px 2.60952px rgba(0, 0, 0, 0.25); text-transform: uppercase; padding-bottom: 6px; }
.m-m-middle-item-play-cont { position: absolute; right: 0px; }
.m-m-middle-item-play-button { height: 80px; width: 80px; background-color: transparent; border: 0px none; }
.m-m-middle-item-play-button-image { height: 27px; width: 27px; }
/* .pause .m-m-middle-item-inner { background: linear-gradient(180deg, #423B56 0%, #563A4E 100%); box-shadow: 0px 4px 50px rgba(73, 79, 232, 0.25); border-radius: 10px; } */
.m-m-middle-inner-cont { padding-top: 25px; max-width: 1100px; margin: 0 auto; }
/* .m-m-middle-inner-cont.h-j-middle-inner-cont { max-width: 100%; } */
.m-m-bottom-button { padding: 32px 46px; background-color: #C1F7F3; color: #000; text-transform: uppercase; font-weight: normal; font-size: 18px; line-height: 25px; text-align: center; letter-spacing: 2.52736px; text-transform: uppercase; color: #000000; border-radius: 126px; border: 0px none }
/* = = = = = = = = mm = = = = = = = = */

/* = = = = = = = = ct = = = = = = = = */
.c-t-cont { background-color: #F8EDFC; position: relative; }
.c-t-cont-rel { padding: 77px 0px 0px; position: relative; overflow: hidden; }
.c-t-inner-abs-image { position: absolute; width: 1000px; height: 1013px; background-image: url('images/image-back.png'); background-position: center; background-repeat: no-repeat; background-size: cover; top: -200px; right: 87px; z-index: 1; display: none; }
.c-t-carousel-item { border-radius: 10px; display: inline-block; overflow: hidden; }
.c-t-carousel-item-inner { background-position: center; background-repeat: no-repeat; background-size: cover; background-size: cover; position: relative; border-radius: 10px; overflow: hidden; }
.c-t-carousel-item { padding-left: 10px; padding-right: 10px; }
.c-t-carousel-cont-abs { position: absolute; z-index: 2; padding-left: 700px; top: 77px; width: 100%; }
.c-t-carousel-cont { position: relative; }
.c-t-carousel-cont:before { position: absolute; width: 80px; height: 100%; left: 0px; top: 0px; background: rgb(248,237,252);
  background: linear-gradient(90deg, rgba(248,237,252,1) 0%, rgba(248,237,252,0) 100%); z-index: 12; content: ''; width: 80px }
.c-t-carousel-cont.first-slide:before { background-color: #F8EDFC; width: 50px }
.c-t-carousel-cont:after { position: absolute; width: 80px; height: 100%; right: 0px; top: 0px; background-color: #F8EDFC; z-index: 12; content: ''; background: rgb(248,237,252);
  background: linear-gradient(270deg, rgba(248,237,252,1) 0%, rgba(248,237,252,0) 100%); }
/* .c-t-carousel-cont { -webkit-box-shadow: 1px 2px 12px 1px rgba(0,0,0,0.44); -moz-box-shadow: 1px 2px 12px 1px rgba(0,0,0,0.44); box-shadow: 1px 2px 12px 1px rgba(0,0,0,0.44); } */
.c-t-inner-cont { min-height: 500px; }
.c-t-carousel-item-bottom-left { padding-left: 20px; font-weight: normal; font-size: 14px; line-height: 19px; color: #FFFFFF; }
.c-t-carousel-item-bottom-left-title { font-weight: 700; }
.c-t-carousel-item-bottom-cont { padding-bottom: 10px; position: absolute; left: 0px; bottom: 0px; width: 100%; }
.c-t-carousel-item-bottom-right { padding-right: 0px; }
.m-m-middle-item-play-button-icon { color: #fff; font-size: 30px !important; margin-left: 5px; }
.pause .m-m-middle-item-play-button-icon { margin-left: 0px; }
.m-m-middle-item-play-button-icon.white { color: #fff;  }
.m-m-bottom-item-play-button { color: #fff; border-color: #fff; }
.c-t-title-cont { position: absolute; left: 66px; top: 130px; z-index: 20; }
.c-t-title { font-family: 'cocosharpextrabold'; font-style: normal; font-weight: normal; font-size: 48px; line-height: 58px; color: #232323; max-width: 500px; font-weight: bold; }
.c-t-subtitle { font-style: normal; font-weight: normal; font-size: 18px; line-height: 25px; color: #232323; max-width: 500px; padding-top: 20px; } 
.c-t-inner-abs-image-bottom { position: absolute; width: 100%; height: 270px; background-image: url('images/wave.png'); background-position: top center; background-repeat: no-repeat; background-size: cover; bottom: 0px; left: 0px; z-index: 0; }
.c-t-bottom-cont { padding-top: 30px; }
.t-m-title-text { font-family: 'cocosharpextrabold'; font-style: normal; font-weight: normal; font-size: 48px; line-height: 58px; text-align: center; color: #fff; padding-top: 60px; font-weight: bold; }
.t-m-inner-cont { background-color: #BFA3CB; }
.t-m-inner-abs-image { position: absolute; width: 100%; height: 270px; background-image: url('images/wave-bottom.png'); background-position: bottom center; background-repeat: no-repeat; background-size: cover; bottom: 0px; left: 0px; z-index: 0; }
.t-m-cont { padding-bottom: 0px; position: relative; }
/* = = = = = = = = ct = = = = = = = = */

/* = = = = tf = = = = */
.h-j-cont { overflow: hidden; background-color: #F8EDFC; }
.h-j-outer-cont { padding-top: 50px; position: relative; padding-bottom: 0px; }
.h-j-abs-image-cont { position: absolute; width: 835px; height: 784px; background-image: url('images/healing-journey-back.png'); background-position: bottom center; background-repeat: no-repeat; background-size: cover; top: -350px; left: 50%; margin-left: -417px; z-index: 0; }
.h-j-title { /*padding: 0 324px;*/ font-family: 'cocosharpextrabold'; font-size: 48px; line-height: 58px; text-align: center; color: #232323; padding-top: 0px; padding-bottom: 40px; max-width: 700px; margin: 0 auto;; }
.h-j-box-item-inner-cont{ border: 3px solid #CEBAEF; box-sizing: border-box; border-radius: 10px; position: relative; height: 100%; }
.h-j-box-item-cont { height: 100%; }
.h-j-box-item-content-item { padding-left: 83px; position: relative; font-size: 18px; line-height: 25px; color: #000000; padding-right: 48px; padding-bottom: 20px; }
.h-j-box-item-title-cont { font-weight: normal;font-size: 35px;line-height: 49px; text-align: center; color: #232323; padding-top: 46px; font-weight: 700; padding-bottom: 30px; font-family: 'cocosharpextrabold'; }
.h-j-box-item-content-item:before { height: 20px; width: 25px; left: 30px; top: 2px; position: absolute; content: ''; background-image: url('images/check-violet.png'); background-size: contain; background-repeat: no-repeat; background-position: center; }
.h-j-b-v-box { position: absolute; top: -30px; left: 0px; width: 100%; height: 58px; }
.h-j-b-v-box-inner { padding: 15px 37px; background-color: #C2F7EA; font-weight: normal; font-size: 13.4px; line-height: 25px; text-align: center; letter-spacing: 2px; text-transform: uppercase; color: #000000;font-family: 'cocosharpextrabold'; }
.h-j-box-item-cont.violet .h-j-box-item-inner-cont { border: 3px solid #916AAA; background-color: #916AAA; }
.violet .h-j-box-item-content-item:before { background-image: url('images/check-white.png'); }
.violet .h-j-box-item-title-cont { color: #fff; }
.violet .h-j-box-item-content-item { color: #fff }
.h-j-boxes-cont { padding-bottom: 30px; }
.h-j-box-item-content-cont { padding-bottom: 20px; }
.h-j-bottom-button-cont { padding-top: 50px; }
/* = = = = tf = = = = */

.shadow-effect {
  background: #fff;
  padding: 20px 20px 28px;
  border-radius: 10px;
  text-align: center;
  /* border: 1px solid #ECECEC; */
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 15px 12px rgba(0, 0, 0, 0.02); */
}
.shadow-effect > div { overflow: visible; }
.testimonial-text-cont { padding-top: 20px; }

/* #customers-testimonials { margin-right: -180px; margin-left: -180px; } */

#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 18px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 400;
  color: #000;
}

.testimonial-name {
  margin: -17px auto 0;
  display: table;
  /* width: 100% !important; */
  background: #3190E7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}

#customers-testimonials .item {
  text-align: center;
  /* padding: 50px; */
  margin-bottom: 80px;
  opacity: 1;
  /* min-width: 100%; */
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;  
}

#customers-testimonials .slick-current .item {
  opacity: 1;
  /* -ms-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  transform: scale(1.08); */
}

#customers-testimonials .slick-initialized .slick-slide.slick-active:not(.slick-current) {
  opacity: 0.8;
  -ms-transform: translate(-20px, 0);
  -webkit-transform: translate(-20px, 0);
  transform: translate(-20px, 0);
}

#customers-testimonials .slick-initialized .slick-slide.slick-active.slick-current + .slick-slide.slick-active {
  opacity: 0.8;
  -ms-transform: translate(20px, 0);
  -webkit-transform: translate(20px, 0);
  transform: translate(20px, 0);
}

.shadow-effect {
  margin-top: 100px !important;
}

.shadow-effect p {
  padding-left: 32px;
  padding-right: 32px;
  font-size: 18px !important;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  text-align: center;
}

#customers-testimonials .item img.img-circle {
  transform-style: preserve-3d;
  max-width: 100px;
  margin: 0 auto 25px;
  margin-top: -70px;
  border-radius: 50px;
}

.testimonialName {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.5);
}
.t-m-slider-cont { width: 100%; overflow: hidden; padding-top: 10px; }
#customers-testimonials .center .slick-center .item { opacity: 1; }
.testimonial-star-item { width: 20px; height: 20px; margin: 0 5px; }
.testimonial-star-item-image { width: 20px; height: 20px; }
.testimonial-star-cont { padding-bottom: 15px; }
.testimonial-header-text { font-size: 24px; line-height: 33px; font-family: 'cocosharpextrabold'; text-align: center; color: #000000; padding-bottom: 13px; padding-top: 5px; }
.t-m-slider-inner-cont { width: 100%; }

/*HEADER*/
.h-d-top-image { height: 50px; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s; }
.h-d-top-cont { height: 130px; position: fixed; z-index: 10; width: 100%; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s; z-index: 99999 }
.header-fixed .h-d-top-cont { background-color: #714694; }
.header-fixed .h-d-top-cont, .header-fixed .h-d-top-cont-inner-cont { height: 70px }
.header-fixed .h-d-top-button-cont { top: 15px; }
.header-fixed .h-d-top-image { height: 40px }
.h-d-top-button-cont { position: absolute; right: 20px; top: 40px; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s; }
.h-d-banner-left-title { color: #fff; font-family: 'cocosharpextrabold'; font-size: 57.9089px; line-height: 69px; }
.h-d-banner-left-title-highlight { font-family: 'cocosharpextrabold'; font-size: 57.9089px; line-height: 69px; color: #C2F7EA; }
/* .h-d-banner-left-title-highlight:after { content: ''; height: 10px; width: 100%; position: absolute; bottom: 0px; display: block; background-image: url('images/small-wave-new.png'); background-position: bottom center; background-repeat: repeat-x; background-size: contain; } */
.h-d-banner-left-title-cont { max-width: 380px; }
/* .t-i-outer-cont .h-d-banner-left-title-cont { margin: 0 auto; } */
.h-d-banner-left { padding-left: 20px; padding-top: 140px; position: relative; z-index: 2; }
.h-d-banner-left-buttons-cont { padding-top: 40px; }
.h-d-banner-left-buttons-cont .app-button, .h-d-banner-left-buttons-cont .app-button-outline { width: 250px; }
.h-d-banner-left-buttons-cont .app-button-outline { margin-top: 20px; }
.h-d-banner-left-buttons-cont .app-button-outline-icon { font-size: 10px; }
.h-d-banner-left-buttons-cont .app-button-outline-text { font-size: 10px; }
.h-d-inner-cont { position: relative; overflow: hidden; padding-bottom: 100px; }
.h-d-inner-cont.no-pad-bottom { padding-bottom: 0px; }
.h-d-abs-img { position: absolute; width: 716px; height: 705px; background-image: url('imagesV2/header-back.png'); background-position: 0 0; background-repeat: no-repeat; background-size: cover; top: 0px; left: 0px; z-index: 0; box-shadow: 0 0 0 0 rgba(218, 199, 255, 1); animation: pulse-orange 2s infinite; }
@keyframes pulse-orange {
  0% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0 rgba(218, 199, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(218, 199, 255, 0);
  }
  
  100% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0 rgba(218, 199, 255, 0);
  }
}


.h-d-banner-left-button-left { margin-right: 20px; }
.h-d-banner-app-image-cont { width: 881px; height: 941px; right: 0px; position: absolute; top: 0px; z-index: 1 }
.h-d-banner-app-image { width: 100%; height: auto; }
.h-d-banner-inner-cont { position: relative; z-index: 100; }
.h-d-banner-cont { position: relative; padding-bottom: 220px; }
.h-d-banner-cont-abs-image-bottom { position: absolute; width: 100%; height: 100px; background-image: url('imagesV2/wave.png'); background-position: top center; background-repeat: no-repeat; background-size: cover; bottom: 0px; left: 0px; z-index: 0; }
.h-d-stats-item { padding: 0 90px }
.h-d-stats-item-image { height: 50px; }
.h-d-stats-item-title { font-weight: 300; font-size: 60.3195px; line-height: 72px; text-align: center; text-transform: uppercase; color: #fff; font-family: 'cocosharpbold'; padding-top: 15px; padding-bottom: 0px; }
.h-d-stats-item-subtitle { font-weight: normal; font-size: 14px; line-height: 17px; text-align: center; letter-spacing: 1.88498px; text-transform: uppercase; color: #fff; }
.h-d-stats-inner-cont { padding-bottom: 70px; }
.h-d-stats-cont { position: relative; z-index: 10; }

.h-d-outer-cont {  background: rgb(197,163,203); background: linear-gradient(135deg, rgba(197,163,203,1) 0%, rgba(144,89,216,1) 100%); }
.h-d-top-cont-inner-cont { position: relative; height: 130px; padding-left: 20px; }
.h-d-top-cont-invisible { height: 130px; position: relative; z-index: 10; padding-left: 20px; opacity: 0; }
/*HEADER*/

/*TI*/
.t-i-right-cont .h-d-banner-left-title { color: #1F1E22; text-align: center; font-size: 42px; line-height: 50px; font-family: 'cocosharpextrabold'; }
.t-i-right-cont .h-d-banner-left-subtitle { text-align: center; }
.t-i-right-cont { padding-left: 789px; padding-top: 110px; }
.t-i-inner-cont { position: relative; background-color: #F8EDFC; padding-bottom: 200px; padding-bottom: 0px; }
.t-i-abs-image-top { position: absolute; width: 100%; height: 100px; background-image: url('imagesV2/wave-back-new-2.png'); background-position: top center; background-repeat: repeat-x; background-size: contain; bottom: -15px; left: 0px; z-index: 0; transform: rotateX(135deg); -moz-transform: rotateX(135deg); -webkit-transform: rotateX(135deg); }
.h-d-banner-left-subtitle { font-size: 18px; line-height: 22px; color: #000000; padding-top: 20px; }
.t-i-right-button-cont { padding-top: 35px; }
.t-i-right-image-cont { position: absolute; width: 600px; height: 574px; top: -100px; left: 55px; }
.t-i-right-cont { position: relative; z-index: 3; padding-bottom: 100px; }
.t-i-inner-abs-image-bottom { position: absolute; width: 600px; height: 670px; background-image: url('images/doughnut.png'); background-position: center center; background-repeat: no-repeat; background-size: cover; bottom: -300px; left: 50%; z-index: 0; margin-left: -400px; }
.t-i-right-image-rel { height: 100%; width: 100%; }
.t-i-right-image-abs { position: absolute; left: 0px; top: 0px; background-position: center center; background-repeat: no-repeat; background-size: cover; height: 100%; width: 100%; z-index: 0; border-radius: 5px; }
.t-i-right-slider-image { /*max-width: 350px;*/ height: auto; }
.t-i-right-image-slider { position: relative; z-index: 3; width: 100%; }
.t-i-right-image-slider-image-cont { max-width: 350px; }
.t-i-right-image-slider-slick-cont { padding: 60px 72px 60px; }
.t-i-right-image-slider-slick-cont .slick-slide { max-width: 350px; }
.t-i-right-image-slider-slick-cont .slick-slider { max-width: 550px; padding: 0 100px; margin: 0 auto; }
.t-i-right-image-slider-slick-cont .slick-prev:before,.t-i-right-image-slider-slick-cont  .slick-next:before { display: none; }
.t-i-right-image-slider-slick-cont .slick-prev:after,.t-i-right-image-slider-slick-cont  .slick-next:after { display: none; }
.t-i-right-cont .h-d-banner-left-button-left { margin-right: 0px; }
/* .mobile-slide-item { margin: 0 auto; width: 100%; } */
/*TI*/

/* = = FOOTER = = */
.f-o-o-abs-image-cont { position: absolute; width: 100%; height: 50px; background-image: url('imagesV2/wave-footer.png'); background-position: top center; background-repeat: no-repeat; background-size: cover; top: 0px; left: 0px; z-index: 0; transform: rotate(180deg); -moz-transform: rotate(180deg); -webkit-transform: rotate(180deg); }
.f-o-o-outer-cont { position: relative; padding-top: 130px; }
.f-o-o-outer-cont.less-pad-top { padding-top: 50px; }
.f-o-o-inner-cont { background-color: #fff; }
.f-o-o-top-left-logo-image { max-width: 118px; }
.f-o-o-top-left-button-image { width: 209px; height: 62px; }
.f-o-o-top-left-button { border: 0px none; background-color: transparent; padding-bottom: 20px; }
.f-o-o-top-left-btns-cont { padding-top: 10px; }
/* .f-o-o-top-left { padding-left: 60px; } */
.f-o-o-top-right-text-f { height: 50px; border-radius: 25px; background-color: #fff; border: 1px solid #A381B8; width: 100%; padding-left: 20px; font-weight: bold; font-size: 18px; line-height: 22px; color: rgba(0, 0, 0, 0.2); font-family: 'cocosharpregular'; width: 230px; }
input.f-o-o-top-right-text-f:focus { border: 1px solid #A381B8; opacity: 1; color: rgba(0, 0, 0, 1); font-family: 'cocosharpbold'; }
.f-o-o-top-right-text-f::placeholder { color: rgba(0, 0, 0, 0.2); }
.f-o-o-top-right-text-f:focus::placeholder { color: rgba(0, 0, 0, 1); }
.f-o-o-top-right-title { font-size: 24px; line-height: 33px; text-align: left; color: #000000; font-family: 'cocosharpextrabold'; padding-bottom: 5px; }
.f-o-o-top-right-subtitle { font-style: normal; font-weight: normal; font-size: 14px; line-height: 17px; color: #000000; padding-bottom: 17px; }
.f-o-o-top-right-text-f-cont { padding-right: 10px; }
/* .f-o-o-top-right { padding-right: 70px; padding-left: 20px; } */
.f-o-o-top-right-content-cont .app-button { padding-left: 40px; padding-right: 40px; }
.f-o-o-bottom-cont { padding-top: 60px; }
.f-o-o-social-button-image { width: 39px; height: 39px; }
.f-o-o-social-button-image.no-width { width: auto; }
.f-o-o-social-button { border: 0px none; margin-right: 15px; margin-left: 15px;; background-color: transparent; }
.f-o-o-bottom-reserved { font-size: 18px; line-height: 25px; text-align: center; color: #000000; text-align: center; }
.f-o-o-bottom-reserved.top { padding-top: 30px; }
.f-o-o-bottom-reserved a { color: #916AAA; text-decoration: none; }
.f-o-o-bottom-inner-cont { padding-bottom: 30px; }
/* .email-inner-cont-modal-wrapper { position: relative; } */

.email-close-button-cont-modal-wrapper { position: absolute; right: 10px; top: 10px; height: 50px; width: 50px; background-color: transparent; border: 0px none; }
.email-close-button-cont-modal-icon { font-size: 25px; }
.email-cont-modal { padding: 20px 50px 50px; background-color: #fff; border-radius: 20px; border: 0px none; position: relative; }
.e-p-tf-cont-text { height: 50px; border-radius: 5px; background-color: #fff; border: 1px solid rgb(161, 161, 161); width: 100%; padding-left: 20px; }
.email-inner-cont .app-button { width: 100%; height: 50px; margin-top: 10px; border-radius: 5px; }
.email-inner-cont .e-p-tf-cont { width: 100%; }
.e-p-title { text-align: center; font-size: 35px; line-height: 40px; margin: 0 auto; color: #000; padding-top: 20px; padding-bottom: 20px; }
.e-p-title .h-d-banner-left-title-highlight { font-size: 35px; line-height: 40px; }
input.e-p-tf-cont-text:focus { border: 1px solid rgb(161, 161, 161); }
.e-p-tf-cont.mb-10 { margin-bottom: 10px; }

.h-j-box-item-cont-outer > div { overflow: visible !important; }
/* = = FOOTER = = */


/* = = = = RESPONSIVE = = = = */
@media all and (max-width: 1300px) {
  .t-i-right-cont { padding-left: 600px; }
  .t-i-right-image-cont { width: 525px; left: 0px }
  
  .t-i-right-slider-image { max-width: 250px; height: auto; }
  .t-i-right-image-slider { position: relative; z-index: 3; width: 100%; }
  .t-i-right-image-slider-image-cont { max-width: 250px; }
  .t-i-right-image-slider-slick-cont { padding: 85px 20px 0px; }
  .t-i-right-image-slider-slick-cont .slick-slide { max-width: 250px; }
  .t-i-right-image-slider-slick-cont .slick-slider { max-width: 250px; padding: 0px; margin: 0 auto; }
  .t-i-right-image-slider-slick-cont .slick-prev:before,.t-i-right-image-slider-slick-cont  .slick-next:before { display: none; }
  .t-i-right-image-slider-slick-cont .slick-prev:after,.t-i-right-image-slider-slick-cont  .slick-next:after { display: none; }
  .slick-prev, .slick-next { right: -80px !important }
  .slick-prev { left: -80px !important }
  .c-t-carousel-cont-abs { padding-left: 566px; }

  /* .c-t-carousel-item-inner { max-width: 300px;  } */
  /* .c-t-carousel-item { height: 417px; min-width: 300px; } */
  /* .c-t-carousel-inner-cont .slick-slide { max-width: 320px; } */
  /* .t-i-right-image-slider-slick-cont .slick-slider { max-width: 320px; } */
}

@media all and (max-width: 1200px) {
  .h-d-banner-left { padding-left: 20px; }
  .h-d-banner-left-title, .h-d-banner-left-title-highlight, .m-m-top-title, .t-m-title-text, .h-j-title, .c-t-title { font-size: 48px; line-height: 1.3em; }
  .m-m-cont { padding: 50px 50px; }
  /* .m-m-top-cont { padding: 0 100px; } */
  /* .f-o-o-top-left-btns-cont { padding-left: 50px; } */
  .c-t-title-cont { left: 20px; top: 90px }
  .c-t-carousel-cont-abs { padding-left: 450px }
  .c-t-inner-cont { min-height: 480px; }
  .c-t-subtitle, .c-t-title { max-width: 400px }
}

@media all and (max-width: 1024px) {
  .h-d-banner-app-image-cont { width: 600px; height: 640px; right: 0px; top: 90px }
  .h-d-banner-left { padding-top: 80px; }
  .h-d-stats-inner-cont { padding-bottom: 0px; }
  .t-i-right-cont { padding-left: 425px; padding-right: 20px; height: 570px; padding-top: 60px; }
  .t-i-right-image-cont { width: 400px; height: 570px; top: 0px; left: 0px }
  .h-d-stats-item { padding: 0 40px }
  .t-i-right-slider-image { max-width: 200px; height: auto; }
  .t-i-right-image-slider { position: relative; z-index: 3; width: 100%; }
  .t-i-right-image-slider-image-cont { max-width: 200px; }
  .t-i-right-image-slider-slick-cont { padding: 85px 20px 0px; }
  .t-i-right-image-slider-slick-cont .slick-slide { max-width: 200px; }
  .t-i-right-image-slider-slick-cont .slick-slider { max-width: 200px; padding: 0px; margin: 0 auto; }
  .t-i-right-image-slider-slick-cont .slick-prev:before,.t-i-right-image-slider-slick-cont  .slick-next:before { display: none; }
  .t-i-right-image-slider-slick-cont .slick-prev:after,.t-i-right-image-slider-slick-cont  .slick-next:after { display: none; }
  .slick-prev, .slick-next { right: -50px !important }
  .slick-prev { left: -50px !important }
  .m-m-cont { padding: 50px 20px; }
  /* .m-m-top-cont { padding: 0 50px; } */
  /* #customers-testimonials { margin-right: -250px; margin-left: -250px; } */
  /* .h-j-title { padding: 80px 100px } */
  .h-j-box-item-title-cont { padding: 30px 20px 10px; font-size: 32px; }
  .h-j-box-item-content-item { padding-left: 60px; }
  .h-j-box-item-content-item:before { left: 20px; width: 25px }
  /* .f-o-o-top-left-btns-cont { padding-left: 20px; } */
  /* .f-o-o-top-left-logo-image {     max-width: 100px; } */
  /* .h-d-banner-left-title-cont { max-width: 100%; } */
  .h-d-banner-cont { padding-bottom: 140px; }
  .t-i-outer-cont .h-d-banner-left-title-cont { margin: 0 auto; }
}

@media all and (max-width: 991px) {
  #customers-testimonials { margin-left: 0px;margin-right: 0px; }
  .h-j-box-item-cont-outer { margin-bottom: 40px; padding-top: 30px; height: 100%; }
  .h-j-middle-inner-cont { padding-top: 0px; }
  .m-m-middle-item-image { height: 100px }
  .m-m-cont { padding: 50px 0px }
  .f-o-o-top-left { padding-left: 0px; }
  .f-o-o-top-left-logo-cont { text-align: center; padding-bottom: 60px; }
  .f-o-o-top-left-logo-cont img { display: inline-block; }
  .f-o-o-top-left-btns-cont { padding-left: 0px; padding-bottom: 60px; }
  .f-o-o-top-left-btns-cont button { display: block; }
  .f-o-o-top-right { padding-left: 0px; padding-right: 0px; }
  .f-o-o-top-right-title { text-align: center; padding: 0 0px 10px }
  .f-o-o-top-right-text-f-cont { padding-right: 0px; padding-bottom: 20px; }
  .f-o-o-top-right-text-button { min-width: 150px }
  .f-o-o-top-right-text-f-cont { width: 100%; }
  .f-o-o-bottom-cont { padding-top: 60px; }
  .c-t-title-cont { position: relative; left: 0px; top: 0px; z-index: 5; }
  .c-t-carousel-cont-abs { padding-left: 0px; position: relative; left: 0px; top: 0px; z-index: 5; }
  .c-t-title, .c-t-subtitle { max-width: 100%; text-align: center; }
  .c-t-title-cont { padding-right: 20px; padding-left: 20px; text-align: center; }
  .c-t-cont { padding-top: 50px; }
  .c-t-title-cont { padding-bottom: 40px; }
  /* .t-i-inner-cont { padding-bottom: 150px; } */
  .c-t-cont-rel { padding: 0px 0px 77px; }
  .f-o-o-top-right-text-f { width: 300px; }
  .f-o-o-top-right-content-cont .app-button { width: 300px; }
}

@media all and (max-width: 900px) {
  .h-d-banner-app-image-cont { right: 0px;}
  .h-d-banner-left { padding-left: 20px; }
  .app-button, .app-button-outline { height: 50px; padding-left: 25px; padding-right: 25px; }
  .app-button .app-button-text, .app-button-outline .app-button-outline { font-size: 10px; }
  /* .app-button.big, .app-button-outline.big { height: 60px; padding-left: 30px; padding-right: 30px; } */
  .app-button.big .app-button-text, .app-button-outline.big .app-button-outline-text { font-size: 12px; }
  .app-button-outline-icon { font-size: 15px; }
  .f-o-o-top-right-text-f { height: 50px; padding-left: 25px; padding-right: 25px; }
}

@media all and (min-width: 768px) {
  .h-d-banner-mobile-cont-abs-image-bottom { display: none; }
  .t-i-bottom-image-mobile-cont { display: none; }
}

@media all and (max-width: 767px) {
  .h-d-banner-left { padding-left: 20px; padding-top: 20px; }
  .h-d-banner-left-title-cont { max-width: 100%; text-align: center; }
  .h-d-banner-left-title, .h-d-banner-left-title-highlight { text-align: center; }
  .h-d-banner-left-buttons-cont { padding-top: 30px; }
  .h-d-banner-app-image-cont { position: relative; text-align: center; width: 100%; left: 0; top: 0px; height: auto; left: 58px; }
  .h-d-banner-app-image-cont img { display: inline-block; }
  .h-d-banner-left-title-highlight { display: inline-block; margin: 0 auto }
  .h-d-banner-left-buttons-cont { padding-left: 0px; padding-right: 0px; display: block !important; }
  .h-d-banner-left-buttons-cont .app-button, .h-d-banner-left-buttons-cont .app-button-outline { width: 100%; }
  .h-d-banner-left-buttons-cont .app-button { margin-bottom: 30px; }
  .h-d-banner-left { padding-left: 20px; padding-right: 20px; }
  .h-d-banner-left-title, .h-d-banner-left-title-highlight, .m-m-top-title, .t-m-title-text, .h-j-title, .c-t-title { font-size: 42px; }
  .h-d-top-image { height: 35px; }
  .h-d-top-cont { height: 80px }
  .h-d-top-cont-inner-cont { height: 80px; }
  .h-d-top-button-cont { top: 20px; right: 10px }
  .h-d-top-button-cont .app-button { height: 40px; padding-left: 10px; padding-right: 10px; }
  /* .h-d-banner-mobile-cont-abs-image-bottom { position: absolute; width: 100%; height: 500px; background-image: url('images/wave.png'); background-position: top center; background-repeat: no-repeat; background-size: cover; bottom: 0px; left: 0px; z-index: 0; display: block; } */
  .h-d-banner-cont { padding-bottom: 40px; }
  .h-d-stats-item-image { height: 43px; }
  .h-d-stats-item-title { font-size: 33px; line-height: 1.2em;  }
  .h-d-stats-item-subtitle { font-size: 12px; }
  .h-d-stats-item { padding: 0 20px; }

  

  .t-i-right-cont { padding-left: 20px; padding-right: 20px; height: auto; padding-bottom: 40px; }
  .t-i-right-image-cont { width: 100%; height: auto; position: static; padding-left: 30px; padding-right: 30px; }
  
  .t-i-right-slider-image { max-width: 100%; height: auto; }
  .t-i-right-image-slider { position: relative; z-index: 3; width: 100%; }
  .t-i-right-image-slider-image-cont { max-width: 100%; }
  .t-i-right-image-slider-slick-cont { padding: 100px; }
  .t-i-right-image-slider-slick-cont .slick-slide { max-width: 100%; }
  .t-i-right-image-slider-slick-cont .slick-slider { max-width: 100%; padding: 0px; margin: 0 auto; }
  .t-i-right-image-slider-slick-cont .slick-prev:before,.t-i-right-image-slider-slick-cont  .slick-next:before { display: none; }
  .t-i-right-image-slider-slick-cont .slick-prev:after,.t-i-right-image-slider-slick-cont  .slick-next:after { display: none; }
  .slick-prev, .slick-next { right: -50px !important }
  .slick-prev { left: -50px !important }
  .t-i-right-image-rel { position: relative; }
  .t-i-inner-abs-image-bottom { right: -200px }
  .h-d-banner-left-subtitle, .m-m-top-subtitle { font-size: 18px; line-height: 1.2em; }
  .m-m-cont { padding: 50px 0px; }
  .m-m-top-cont { padding: 0px; }
  .m-m-middle-item-image { height: 100px }
  .h-d-stats-inner-cont { padding-bottom: 0px; }
  #customers-testimonials { margin-right: 0px; margin-left: 0px; width: 100%; }
  /* #customers-testimonials .item { opacity: 0.5; } */
  #customers-testimonials .shadow-effect p { font-size: 18px !important; padding-left: 10px; padding-right: 10px; }
  #customers-testimonials .item  img.img-circle { max-width: 60px; margin-top: -50px; margin-bottom: 20px; }
  .shadow-effect { margin-top: 50px !important; }
  .t-m-title-text { padding-left: 20px; padding-right: 20px; }
  /* #customers-testimonials .item { margin-left: 10px; margin-right: 10px; } */
  .h-j-abs-image-cont { width: 100%; background-size: cover; margin-left: -50%; }
  .h-j-title { padding: 20px 20px 40px }
  .m-m-top-cont { padding-left: 30px; padding-right: 30px; }
  .f-o-o-bottom-cont { padding-left: 20px;padding-right: 20px; }
  .h-j-box-item-cont-outer { padding-left: 10px; padding-right: 10px; }
  .t-i-inner-cont { padding-bottom: 0px; }


  .h-d-abs-img { width: 100%; }
  .h-d-stats-cont { padding-top: 20px; }
  .t-i-abs-image-top { height: 70px }
  .t-i-bottom-image-mobile-cont { display: block; }
  .t-i-bottom-image-mobile { width: 100%; }
  .h-j-boxes-cont { padding-bottom: 0px; }
  .m-m-bottom-inner-cont .app-button { width: 100%; }
  .m-m-bottom-inner-cont { padding-left: 20px; padding-right: 20px; }
  .c-t-cont .m-m-bottom-inner-cont { padding-left: 0px; padding-right: 0px; }
  .h-j-bottom-button-cont .app-button { width: 100%; }
  .f-o-o-top-right-content-cont, .f-o-o-top-right-text-f, .f-o-o-top-right-content-cont .app-button { width: 100%; }
}

@media all and (max-width: 600px) {
  .c-t-carousel-cont.first-slide:before { position: absolute; width: 80px; height: 100%; left: 0px; top: 0px; background: rgb(248,237,252);
    background: linear-gradient(90deg, rgba(248,237,252,1) 0%, rgba(248,237,252,0) 100%); z-index: 12; content: ''; width: 80px }
}

@media all and (max-width: 480px) {
  .t-i-right-image-cont { padding-left: 0px; padding-right: 0px; }
  .t-i-right-image-slider-slick-cont { padding: 50px; }
  .h-d-top-image { height: 30px; }
  .header-fixed .h-d-top-image { height: 30px; }
  .slick-prev { left: -35px !important; }
  .slick-next { right: -35px !important; }
  .h-j-box-item-content-item { padding-right: 10px; }
}
/* = = = = RESPONSIVE = = = = */

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.header-image { position: absolute; left: -20px; top: -20px; z-index: 1; opacity: 0; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
.header-image.show { opacity: 1; }
.header-image.image1 { width: 250px; height: 250px; border-radius: 125px; top: -125px; left: -125px; z-index: 5; background-color: rgba(255, 255, 255, 0.5); }
.header-image.image2 { width: 500px; height: 500px; border-radius: 250px; top: -250px; left: -250px; z-index: 4; background-color: rgba(255, 255, 255, 0.4); }
.header-image.image3 { width: 750px; height: 750px; border-radius: 375px; top: -375px; left: -375px; z-index: 3; background-color: rgba(255, 255, 255, 0.3); }
.header-image.image4 { width: 1000px; height: 1000px; border-radius: 500px; top: -500px; left: -500px; z-index: 2; background-color: rgba(255, 255, 255, 0.2); }
.header-image.image5 { width: 1250px; height: 1250px; border-radius: 625px; top: -625px; left: -625px; z-index: 1; background-color: rgba(255, 255, 255, 0.1); }

.payment-status-cont { background-color: #714694; justify-content: center; align-items: center; display: flex; padding: 50px 0px; }
.payment-status-box { max-width: 500px; background-color: #fff; padding: 50px; justify-content: center; align-items: center; border-radius: 20px; }
.payment-status-box-header { font-size: 2.3rem; color: #444; line-height: 1.3em; padding-top: 20px; padding-bottom: 20px; }
.payment-status-box-icon { color: #009900; font-size: 70px; }
.payment-status-box-icon.yellow { color: #ffcc00 }
.payment-status-box-cont ol li { padding-bottom: 15px; }
.e-p-tf-required-text { color: red; font-style: italic; font-size: 0.8rem; line-height: 1.3em; }
/* .header-image.image4 { width: 541px; height: 533px; border-radius: 150px; z-index: 2; }
.header-image.image5 { width: 720px; height: 712px; border-radius: 150px; z-index: 1; } */
/* .header-image.image1 { background-image: url('./imagesV2/pulse/old/circle1.png'); width: 141px; height: 133px; background-size: cover; z-index: 5; }
.header-image.image2 { background-image: url('./imagesV2/pulse/old/circle2.png'); width: 243px; height: 235px; background-size: cover; z-index: 4; }
.header-image.image3 { background-image: url('./imagesV2/pulse/old/circle3.png'); width: 380px; height: 372px; background-size: cover; z-index: 3; }
.header-image.image4 { background-image: url('./imagesV2/pulse/old/circle4.png'); width: 541px; height: 533px; background-size: cover; z-index: 2; }
.header-image.image5 { background-image: url('./imagesV2/pulse/old/circle5.png'); width: 720px; height: 712px; background-size: cover; z-index: 1; } */
/* .header-image.image1.show { opacity: 1; }
.header-image.image2.show { opacity: 1; }
.header-image.image3.show { opacity: 1; }
.header-image.image4.show { opacity: 1; }
.header-image.image5.show { opacity: 1; } */


.cookies-text-cont { background-color: #fff; padding: 50px 20px; border-radius: 20px; width: 100%; }
.cookies-subheader-text { font-size: 1.2em; line-height: 1.2em; padding-bottom: 20px; }
.cookies-header-text { font-size: 2em; font-family: 'cocosharpbold'; line-height: 1.2em; }
.cookies-module-header { font-size: 1.5em; font-family: 'cocosharpbold'; line-height: 1.2em; color: #444; padding-bottom: 10px; }
.cookie-accept-button { color: #4e503b !important; font-size: 13px !important; height: 40px !important; padding-left: 30px !important; padding-right: 30px !important; background-color: #C2F7EA !important; border-radius: 20px !important; border-width: 0px !important }
.cookie-reject-button { color: #C2F7EA !important; font-size: 13px !important; height: 40px !important; padding-left: 30px !important; padding-right: 30px !important; background-color: transparent !important; border-radius: 20px !important; border-width: 0px !important; border: 1px solid #C2F7EA !important; margin: 15px 10px !important }
.privacy-policy-link { padding-top: 10px; padding-bottom: 10px; }